/* eslint-disable func-style */
import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localePl from '@angular/common/locales/pl';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsModule } from '@ngxs/store';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { ButtonModule } from 'primeng/button';

import { Api } from '../app/api/src/Api';
import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { ThemeService } from './core/services/theme/theme.service';
import { AppState } from './features/modules/state/app.state';
import { TranslationManagerService } from './shared/services/translation-manager.service';
import { CURRENT_LANG } from './shared/tokens/lang.tokens';

registerLocaleData(localePl, `pl`);

// eslint-disable-next-line import/exports-last
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, `./assets/languages/`, `.json`);
}

function translationInitializerFactory(translateService: TranslateService, currentLang: string) {
    translateService.use(currentLang);
    return async () => translateService.use(currentLang).toPromise();
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        NgxsModule.forRoot([AppState], {
            developmentMode: !environment.production,
        }),
        RouterModule.forRoot(
            [
                {
                    path: `admin`,
                    loadChildren: async () => {
                        return import(`./features/admin/admin.module`).then((mod) => {
                            return mod.AdminModule;
                        });
                    },
                },
                {
                    path: ``,
                    loadChildren: async () => {
                        return import(`./features/main/main.module`).then((mod) => {
                            return mod.MainModule;
                        });
                    },
                },
            ],
            {
                paramsInheritanceStrategy: `always`,
            },
        ),
        TranslateModule.forRoot({
            defaultLanguage: `pl`,
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
        BrowserAnimationsModule,
        BrowserModule.withServerTransition({
            appId: `serverApp`,
        }),
        ButtonModule,
        HttpClientModule,
        ScullyLibModule,
    ],
    providers: [
        Api,
        ThemeService,
        {
            provide: LOCALE_ID,
            useValue: `pl`,
        },
        TranslateService,
        {
            provide: CURRENT_LANG,
            useFactory: () => TranslationManagerService.getLanguage(),
        },
        {
            provide: APP_INITIALIZER,
            useFactory: translationInitializerFactory,
            deps: [TranslateService, CURRENT_LANG],
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
