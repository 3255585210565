import { Environment } from './interface';

const theme = `
:root {
    /* Palette */
    --neutral-100: hsl(0, 0%, 93%);
    --neutral-900: rgb(52 52 52);
    --primary-10: rgb(159 179 211);
    --primary-30: rgb(203 67 55);
    --primary-50: rgb(22 64 107);
    --primary-100: rgb(94 128 165);

    /* General */
    --base-bg-color: var(--neutral-100);
    --base-font-color: var(--neutral-900);
    --base-font-family: 'Open Sans Condensed', sans-serif;

    /* Utils */
    --active-font-color: var(--primary-100);
    --text-active: var(--primary-100);

    /* Admin panel */
    --admin-panel-bg: var(--primary-10);

    /* Navigation */
    --navigation-desktop-grid: 50px 1fr auto !important;

    /* Ticket */
    --ticket-bg: var(--primary-10);
    --ticket-text-color: var(--neutral-900);
    --ticket-bg-hover: var(--primary-50);
    --ticket-text-color-hover: var(--neutral-100);

    /* Sections */
    --section-header-font-size: 2.5rem;
    --section-header-line-height: 2;
    --section-header-text-align: center;

    /* Search ticket */
    --search-ticket-bg: var(--primary-50);
    --search-ticket-text-color: var(--neutral-100);
    --search-ticket-text-color: var(--neutral-100);
    --search-ticket-font-size: 1.25rem;
    --search-ticket-text-font-weight: 700;

    /* Shadows */
    --shadow-box-shadow: none;
    --shadow-border: 2px solid #f4f4f4;

    /* Cards */
    --card-mobile-padding: 1.25rem;
    --card-desktop-padding: 1.25rem 1rem;
    --card-header-padding: 0 0 1.25rem 0;
    --card-header-text-align: left;
    --card-header-font-weight: 700;
    --card-border-radius: 5px;
    --card-content-border-left: 2px solid var(--primary-50);
    --card-content-padding: 0 0 0 0.75rem;
    --card-even-bg: transparent;
    --card-even-header-text-color: var(--primary-50);
    --card-even-content-text-color: var(--neutral-900);
    --card-odd-bg: transparent;
    --card-odd-header-text-color: var(--primary-50);
    --card-odd-content-text-color: var(--neutral-900);

    /* Credit card */
    --credit-card-bg: var(--primary-10);

    /* Button bg */
    --button-bg: var(--primary-30);
    --button-bg-hover: var(--primary-100);
    --button-text-color: var(--neutral-100);
    --button-text-color-hover: var(--primary-30);
}
`;

export const environment: Environment = {
    production: false,
    apiUrl: `https://sklep.wkd.com.pl/api`,
    tags: [
       { name: `single-journey`, value: `single-journey` },
        { name: `short-term`, value: `short-term` },
        { name: `periodic`, value: `periodic` },
        { name: `normal`, value: `normal` },
        { name: `reduced`, value: `reduced` },
    ],
    zones: [
        { name: 'zone1', value: 1 },
        { name: 'zone2', value: 2 },
        { name: 'zone3', value: 3 },
    ],
    // Look customization.
    instanceConfiguration: {
        logo: `/assets/local-wkd/logo.png`,
        headHtml: `
<link rel="icon" type="image/x-icon" href="./assets/local/favicon.ico" />
<link rel="preconnect" href="https://fonts.googleapis.com">
<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
<link href="https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300;700&display=swap" rel="stylesheet">
        `,
        theme,
        global: {
            appTitle: `WKD Sklep`,
            captchaKey: `6LfsKtwiAAAAAD6bpFCjM5P5_zvkAGfYXsbwGywu`,
        },
        payments: {
            maxTransactionAwaitTime: 1200,
        },
        contact: {
            companyName: `Warszawska Kolej Dojazdowa sp. z o.o.`,
            companyText: `
            <br>
            05-825 Grodzisk Mazowiecki <br>
            ul. Stefana Batorego 23 (wjazd od ul. Radońskiej) <br>
            <br><br><br>
            Biuro Obsługi Klienta czynne:<br>
            tel. (22) 755-47-60 Biuro <br>
            Poniedziałek – Piątek, 7.00 – 15.00
            `,
            mapUrl: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2450.8824947698895!2d20.62668131594451!3d52.10007007501655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47194896f33ebab9%3A0xe82bd6b5c6ffc95e!2sWarszawska%20Kolej%20Dojazdowa%20Sp.%20z%20o.%20o.!5e0!3m2!1spl!2spl!4v1667569207286!5m2!1spl!2spl`,

        },
        home: {
            mainText: `home.title`,
            textClass: `text-3xl md:text-4xl text-center md:text-left self-center py-10`,
            cards: [
                // `<img class="rounded-full mx-auto pb-6" src="/assets/icons/houses.svg" width="128" height="128" />`,
                // `<img class="rounded-full mx-auto pb-6" src="/assets/icons/houses.svg" width="128" height="128" />`,
                // `<img class="rounded-full mx-auto pb-6" src="/assets/icons/houses.svg" width="128" height="128" />`,
            ],
            sliderTickets: [
                `Normalny 20 minutowy`,
                `Normalny 75 minutowy`,
                `Normalny 90 minutowy`,
                `Ulgowy 20 minutowy`,
                `Ulgowy 75 minutowy`,
                `Ulgowy 90 minutowy`,
                `Normalny na okaziciela 30-dniowy (strefa 1+2)`,
                `Ulgowy na okaziciela 30-dniowy ulgowy (strefa 1+2)`,
                `Ulgowy dobowy na strefę 1 (24h)`,
            ],
        },
    },
};
